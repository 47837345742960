import React from 'react';
import {Checkbox, FormControl, FormLabel, RadioGroup} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const FormRelatedGrouping = ({ controlID, title, changeHandler, showPreferNot, statePreferNot, labelPreferNot, labelID, children }) => {
  showPreferNot = showPreferNot || false;

  return (
      <FormControl id={controlID}>
        <FormLabel id={labelID} className="itemGroupCaption">{title}</FormLabel>
        <RadioGroup aria-labelledby={labelID}>
          {statePreferNot ? '' : children}

          { showPreferNot ? <>
              <FormControlLabel
                  control={<Checkbox checked={statePreferNot} onChange={changeHandler} />}
                  label={labelPreferNot || 'I prefer not to answer'}
              />
          </> : '' }
        </RadioGroup>
      </FormControl>
  );
}

export default FormRelatedGrouping;
