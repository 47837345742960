import React from 'react';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import CloseIcon from '@material-ui/icons/Close';
import FormFileUpload from './formfileupload';
import './css/applynowform.css';
import {Checkbox, FormControl, FormLabel, Radio, RadioGroup} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import FormRelatedGrouping from './formRelatedGrouping';

class ApplyNowForm extends React.Component {
  constructor(props) {
    super(props);

    // this.applyNowForm = React.createRef();
    this._submitApplication = this._submitApplication.bind(this);
    this._change = this._change.bind(this);
    this._doChange = this._doChange.bind(this);
    this._changeRace = this._changeRace.bind(this);

    this.state = {
      'FirstName': '',
      'MiddleName': '',
      'LastName': '',
      'Suffix': '',
      'Phone': '',
      'Email': '',
      'AddToEmailList': '',
      'GenderIdentity': '',
      'GenderIdentityPreferNot': false,
      'GenderPronounPreferNot': false,
      'GenderPronouns': '',
      'Race': {
        'A': false,
        'B': false,
        'I': false,
        'W': false,
        'O': false,
        'U': false,
        'PREFER_NOT': false,
      },
      'RaceOtherText': ''
    }
  }

  _changeRace(event) {
    this._doChangeRace(event, {})
  }

  _doChangeRace(event, extraState) {
    const name = event.target.value;
    const value = !!event.target.checked;

    let newState = extraState || {};
    newState.Race = this.state.Race;
    newState.Race[name] = value;
    this.setState(newState);
  }

  _change(event) {
    this._doChange(event, {})
  }

  _doChange(event, extraState) {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    let newState = extraState || {};
    newState[name] = value;

    this.setState(newState);
  }

  render() {
    const { position, school, close } = this.props;
    const maxfileSize = 10 * 1024 * 1024; // 10M
    const allowedExtensions = 'application/pdf';

//onError={errors => console.log(errors)}>
    return (
      <div>
        <Dialog open={true} scroll="paper" maxWidth="md" fullWidth={true}>
          <DialogTitle id="form-dialog-title">
            <Grid container style={{justifyContent:'space-between'}}>
              <Grid item style={{paddingTop:'12px', paddingBottom:'12px'}}>Apply Now</Grid>
              <Grid item><IconButton aria-label="close" onClick={() => close(false)}><CloseIcon /></IconButton></Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Typography>{position.Title}</Typography>
            <Typography>{school.name}</Typography>
            { school.hasLogo ? (
              <div className="-applynowform-schoolLogo">
                <img
                  alt={school.name}
                  src={"/api/school/"+position.schoolId+"/logo"}
                  title={school.name}
                />
              </div>
            ) : null }

            <ValidatorForm
                  ref="form"
                  className="-applynowform-form"
                  onSubmit={this._submitApplication}
            >
              <FormGroup>
                <TextValidator
                  className="-applynowform-field"
                  onChange={this._change}
                  name="FirstName"
                  label="First Name"
                  value={this.state.FirstName}
                  validators={['required']}
                  errorMessages={['First Name is Required']}
                />

                <TextValidator
                  className="-applynowform-field"
                  onChange={this._change}
                  name="MiddleName"
                  label="Middle Name"
                  value={this.state.MiddleName}
                />

                <TextValidator
                  className="-applynowform-field"
                  onChange={this._change}
                  name="LastName"
                  label="Last Name"
                  value={this.state.LastName}
                  validators={['required']}
                  errorMessages={['Last name is Required']}
                />

                <TextValidator
                  className="-applynowform-field"
                  onChange={this._change}
                  name="Suffix"
                  label="Suffix"
                  value={this.state.Suffix}
                />

                <TextValidator
                  className="-applynowform-field"
                  onChange={this._change}
                  name="Phone"
                  label="Phone Number"
                  value={this.state.Phone}
                  validators={['required']}
                  errorMessages={['Phone Number is Required']}
                />

                <TextValidator
                  className="-applynowform-field"
                  onChange={this._change}
                  name="Email"
                  label="Email Address"
                  value={this.state.Email}
                  validators={['required', 'isEmail']}
                  errorMessages={['Email is Required', 'Provided Email is Invalid']}
                />

                <FormControlLabel
                  control={<Switch name="AddToEmailList" />}
                  label="Add me to your email mailing list."
                />

                <Divider className="fieldDivider" />

                <FormRelatedGrouping
                    changeHandler={
                      (e) => {
                        let newState = {
                          'GenderIdentityPreferNot': e.target.checked,
                          'GenderIdentity': ''
                        }
                        this.setState(newState)
                      }
                    }
                    controlID="gender-identity-group"
                    title="Gender Identity"
                    showPreferNot={true}
                    statePreferNot={this.state.GenderIdentityPreferNot}
                >
                  <TextValidator
                      className="-applynowform-field"
                      style={{'marginTop':'.5rem'}}
                      onChange={this._change}
                      name="GenderIdentity"
                      label="I identify my gender as"
                      value={this.state.GenderIdentity}
                  />
                </FormRelatedGrouping>

                <Divider className="fieldDivider" />

                <FormRelatedGrouping
                    changeHandler={
                      (e) => {
                        let newState = {
                          'GenderPronounPreferNot': e.target.checked,
                          'GenderPronouns': ''
                        }
                        this.setState(newState)
                      }
                    }
                    controlID="gender-pronoun-group"
                    title="Gender Pronoun"
                    showPreferNot={true}
                    statePreferNot={this.state.GenderPronounPreferNot}
                >
                  <TextValidator
                      className="-applynowform-field"
                      style={{'marginTop':'.5rem'}}
                      onChange={this._change}
                      name="GenderPronouns"
                      label="My gender pronoun is"
                      value={this.state.GenderPronouns}
                  />
                </FormRelatedGrouping>

                <Divider className="fieldDivider" />

                {/* RACE */}
                <FormRelatedGrouping
                    changeHandler={
                      (e) => {
                        let newState = {
                          'Race': {
                            'PREFER_NOT': e.target.checked,
                            'I': false,
                            'B': false,
                            'A': false,
                            'W': false,
                            'O': false,
                          },
                          'RaceOtherText': '',
                        }
                        this.setState(newState)
                      }
                    }
                    controlID="race-group"
                    title="Race/Ethnicity"
                    showPreferNot={true}
                    statePreferNot={this.state.Race.PREFER_NOT}
                >
                      <FormControlLabel
                          control={<Checkbox name="RaceA" value="A" checked={this.state.Race.A} onChange={this._changeRace} />}
                          label="Asian"
                      />
                      <FormControlLabel
                          control={<Checkbox name="RaceB" value="B" checked={this.state.Race.B} onChange={this._changeRace} />}
                          label="Black or African American"
                      />
                      <FormControlLabel
                          control={<Checkbox name="RaceH" value="H" checked={this.state.Race.H} onChange={this._changeRace} />}
                          label="Latino"
                      />
                      <FormControlLabel
                          control={<Checkbox name="RaceI" value="I" checked={this.state.Race.I} onChange={this._changeRace} />}
                          label="Native American/Alaska Native"
                      />
                      <FormControlLabel
                          control={<Checkbox name="RaceT" value="T" checked={this.state.Race.T} onChange={this._changeRace} />}
                          label="Native Hawaiian"
                      />
                      <FormControlLabel
                          control={<Checkbox name="RaceW" value="W" checked={this.state.Race.W} onChange={this._changeRace} />}
                          label="White"
                      />
                      <FormControlLabel
                          control={<Checkbox name="RaceO" value="O" checked={this.state.Race.O} onChange={(e) => {
                            let extraState = e.target.checked ? {'RaceOtherText':''} : {};
                            this._doChangeRace(e, extraState);
                          }} />}
                          label="Other"
                      />
                      { this.state.Race.O ?
                          <TextValidator
                              className="-applynowform-field raceOtherText"
                              onChange={this._change}
                              name="RaceOtherText"
                              label="Other, please specify"
                              value={this.state.RaceOtherText}
                          /> : '' }
                </FormRelatedGrouping>

                <Divider className="fieldDivider" />

                {/* Veteran Status */}
                <FormControl>
                  <FormLabel id="applynowform-veteran-group-label" className="itemGroupCaption">Veteran</FormLabel>
                  <RadioGroup
                      aria-labelledby="applynowform-veteran-group-label"
                      name="Veteran"
                      checked={this.state.Veteran}
                      onChange={this._change}
                  >
                    <FormControlLabel value="Yes" control={<Radio />} label="Yes, I am a veteran" />
                    <FormControlLabel value="No" control={<Radio />} label="No, I am not a veteran" />
                    <FormControlLabel value="PREFER_NOT" control={<Radio />} label="I prefer not to answer" />
                  </RadioGroup>
                </FormControl>

              <Divider className="fieldDivider" />

                <FormFileUpload
                  className="-applynowform-field"
                  onChange={this._change}
                  name="coverLetterFile"
                  label="Upload Cover Letter"
                  value={this.state.coverLetterFile}
                  accept=".pdf"
                  validators={['required', 'isFile', 'maxFileSize:'+maxfileSize, 'allowedExtensions:'+allowedExtensions]}
                  errorMessages={['Cover Letter Upload is Required', 'Cover Leter Upload is Invalid', 'Cover Letter Upload Must Be Less Than 10MB', 'Cover Letter Upload Must Be a PDF']}
                  helperText="Allowed File Type: pdf, Max Upload: 10MB"
                />

                <FormFileUpload
                  className="-applynowform-field"
                  onChange={this._change}
                  name="resumeFile"
                  label="Upload Resume/CV"
                  value={this.state.resumeFile}
                  accept=".pdf"
                  validators={['required', 'isFile', 'maxFileSize:'+maxfileSize, 'allowedExtensions:'+allowedExtensions]}
                  errorMessages={['Resume/CV Upload is Required', 'Resume/CV Upload is Invalid', 'Resume/CV Upload Must Be Less Than 10MB', 'Resume/CV Upload Must Be a PDF']}
                  helperText="Allowed File Type: pdf, Max Upload: 10MB"
                />

                <FormFileUpload
                  className="-applynowform-field"
                  onChange={this._change}
                  name="referencesFile"
                  label="Upload References"
                  value={this.state.referencesFile}
                  accept=".pdf"
                  validators={['required', 'isFile', 'maxFileSize:'+maxfileSize, 'allowedExtensions:'+allowedExtensions]}
                  errorMessages={['Reference Upload is Required', 'Reference Upload is Invalid', 'Reference Upload Must Be Less Than 10MB', 'Reference Upload Must Be a PDF']}
                  helperText="Allowed File Type: pdf, Max Upload: 10MB"
                />

                { position.collectAdditionalFiles.map( (additionalFile, index) => (
                    <FormFileUpload
                        key={index}
                        className="-applynowform-field"
                        onChange={this._change}
                        name={"additionalFile_" + additionalFile.label}
                        label={"Upload " + additionalFile.label}
                        value={this.state["additionalFile_" + additionalFile.label]}
                        accept=".pdf"
                        validators={ (additionalFile.required ? ['required'] : []).concat( this.state["additionalFile_" + additionalFile.label] ? ['isFile','maxFileSize:'+maxfileSize, 'allowedExtensions:'+allowedExtensions] : []) }
                        errorMessages={ (additionalFile.required ? ['Upload is Required'] : []).concat( this.state["additionalFile_" + additionalFile.label] ? ['Upload is Invalid', 'Upload Must Be Less Than 10MB', 'Upload Must Be a PDF'] : []) }
                        helperText="Allowed File Type: pdf, Max Upload: 10MB"
                    />
                ))}

              </FormGroup>

              <FormGroup style={{marginBottom:'1em'}}>
                <Button variant="contained" color="secondary" type="submit">Submit Application</Button>
              </FormGroup>
            </ValidatorForm>
          </DialogContent>
        </Dialog>
      </div>
    );
  }


  async _submitApplication(event) {
    const {position, onInProgress, offInProgress} = this.props;
    const form = event.target;

    onInProgress();

    const toBase64 = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

    fetch("/api/positions/" + position.slug + "/apply", {
      method: "POST",
      body: JSON.stringify({
        application: '',
        _method: 'post',
        status: false,
        FirstName: form.FirstName.value,
        MiddleName: form.MiddleName.value || '',
        LastName: form.LastName.value,
        Suffix: form.Suffix.value || '',
        Phone: form.Phone.value,
        Email: form.Email.value,
        AddToEmailList: form.AddToEmailList.value || false,
        coverLetterFile: await toBase64(form.coverLetterFile.files[0]),
        resumeFile: await toBase64(form.resumeFile.files[0]),
        referencesFile: await toBase64(form.referencesFile.files[0]),
        GenderIdentity: this.state.GenderIdentity,
        GenderIdentityPreferNot: this.state.GenderIdentityPreferNot,
        GenderPronounPreferNot: this.state.GenderPronounPreferNot,
        GenderPronouns: this.state.GenderPronouns,
        Race: Object.entries(this.state.Race).filter(([, value]) => value === true).map(([key]) => key),
        RaceOtherText: this.state.RaceOtherText,
        Veteran: this.state.Veteran,
        AdditionalFiles: await Promise.all(
            position.collectAdditionalFiles
              .map ( (additionalFile) => ({
                    label: additionalFile.label,
                    file: form["additionalFile_" + additionalFile.label]
                })
              )
              .filter( ( { file } ) => file.files.length === 1 )
              .map( async (o) => {
                o.file = await toBase64(o.file.files[0]);
                return o;
              } )
        )
      })
    })
    .then(response => response.json())
    .then(({success}) => {
      offInProgress();

      if (success) {
        // show success messages and close dialog
        this.props.close('Your application has been submitted.');
        return;
      }

      // show failed message failed
      this.props.setError('An error has occurred with submitting your application.  Please try again.')
    })
    .catch(() => {
      offInProgress();
      this.props.setError('An error has occurred with submitting your application.  Please try again.')
    });
  }
}

export default ApplyNowForm;