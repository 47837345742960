import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { ValidatorComponent } from 'react-form-validator-core';
import './css/formfileupload.css';

class FormFileUpload extends ValidatorComponent {
  constructor(props) {
    super(props);

    this._fileChange = this._fileChange.bind(this);

    this.state = {
      filename: 'No file selected.',
      isValid: true,
    };
  }

  _fileChange(e) {
    const fileInput = e.currentTarget;
    const { name, onChange } = this.props;

    if (fileInput.files.length === 1) {
      const file = fileInput.files[0];
      this.setState({ isValid: true, filename: file.name });
      onChange({ target: { name: name, value: file } });
      return;
    }

    this.setState({ isValid: false, filename: 'No file selected.' });
    onChange({ target: { name: name, value: '' } });
  }

  render() {
    const {
        label,
        name,
        error,
        errorMessages,
        validators,
        requiredError,
        helperText,
        validatorListener,
        withRequiredValidator,
        onChange,
        value,
        ...rest
    } = this.props;

    const { filename, isValid } = this.state;
    const hasError = !isValid && this.getErrorMessage();

    return (
        <label htmlFor={name} className="-formfileupload-fileFormControl">
          <Button variant="contained" color="primary" component="span">{label}</Button>
          <Typography display="inline" variant="body1" color={hasError ? 'error' : 'initial'}> {filename}</Typography>
          <input type="file" id={name} name={name} onChange={this._fileChange} {...rest} />
          { hasError ? (<p className="MuiFormHelperText-root Mui-error">{this.getErrorMessage()}</p>) : (<p className="MuiFormHelperText-root MuiFormHelperText-filled">{helperText}</p>) }
        </label>
    );
  }
}

export default FormFileUpload;
