import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Language from '@material-ui/icons/Language';
import PostAdd from '@material-ui/icons/PostAdd';
import './css/jobposting.css';


class JobPosting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddtDetails: false,
    }
  }

  render() {
    const { position, school, applyNowForm } = this.props;

    return (
      <Card className="job-posting" raised={true} style={{margin: '2em'}}>
        <CardHeader
          title={position.Title}
          subheader={school.name}
        />

        <CardContent>
            { school.hasLogo ? (<div className="-school-logo">
              <img
                alt={school.name}
                src={"/api/school/"+position.schoolId+"/logo"}
               title={school.name}
             />
           </div>) : null }

          <Typography
            variant="body2"
            color="textPrimary"
            component="div"
            style={{ paddingTop:'1em', paddingBottom:'1em'}}>
              <div dangerouslySetInnerHTML={{__html: position.Description}} />
          </Typography>

          { position.publicAdditionalDetails === "" || position.publicAdditionalDetails === null ? null : <div>
            {this.state.showAddtDetails ? <>
                <Typography
                  variant="body2"
                  color="textPrimary"
                  component="div"
                  style={{ paddingTop:'1em', paddingBottom:'1em'}}>
                    <div dangerouslySetInnerHTML={{__html: position.publicAdditionalDetails}} />
                </Typography>

                <Button onClick={() => this.setState({ showAddtDetails:false })}>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    component="div">
                      Read Less
                  </Typography>
                </Button>
              </> : <>
                <Button onClick={() => this.setState({ showAddtDetails:true })}>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    component="div">
                      Read More
                  </Typography>
                </Button>
              </>}
          </div>}

          <div style={{'padding':'.5em'}}></div>

          { position.positionPDFName ? <div className="downloadButton">
            <Button href={"/api/position/files/"+position.positionPDFName} variant='contained' color='secondary' target="_blank">
              <Typography
                variant="body2"
                color="textPrimary"
                component="div">
                  Download Position Profile
              </Typography>
            </Button>
          </div> : null }

          { position.files.map( (f, i) => <div className="downloadButton" key={i + "download" + f.name}>
            <Button href={"/api/position/files/"+f.name} variant='contained' color='secondary' target="_blank">
              <Typography
                variant="body2"
                color="textPrimary"
                component="div">
                  {f.desc}
              </Typography>
            </Button>
          </div>) }

          {position.dueDate === '9999-99-99' ? null : <>
            <Typography
              variant="body2"
              color="textPrimary"
              component="p"
              style={{ paddingTop:'1em', paddingBottom:'1em'}}>
                Applications Due {this._formatDate(position.dueDate)}
            </Typography>
          </>}
        </CardContent>

        <CardActions disableSpacing style={{justifyContent: 'center'}}>
          { school.schoolUrl ? <Button className="-job-posting-button -button-to-school" color='primary' variant='contained' size='medium' target="_blank" rel="noopener" href={school.schoolUrl}><Language /> <span>Visit Website</span></Button> : null }
          { position.displayApplyNow ? this._getApplyURL(position, applyNowForm) : null }
        </CardActions>
      </Card>
    );
  }

  _getApplyURL(position, applyNowForm) {
    if (position.customApplyURL) {
      return (<Button className="-job-posting-button -button-to-apply" aria-pressed="false" color='secondary' variant='contained' size='medium'  target="_blank" rel="noopener" href={position.customApplyURL}><PostAdd /> <span>Apply Now</span></Button>)
    } else {
      return (<Button className="-job-posting-button -button-to-apply" aria-pressed="false" color='secondary' variant='contained' size='medium' onClick={ () => applyNowForm(position.id) }><PostAdd /> <span>Apply Now</span></Button>)
    }
  }

  _formatDate(date) {
    if (date) {
      const parts = date.split('-');
      const year = parts[0];
      const month = parts[1] - 1;
      const day = parts[2];

      const monthToName = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

      return monthToName[month] + ' ' + day + ', ' + year;
    }

    return '';
  }
}

export default JobPosting;
